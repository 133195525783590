import React from "react";

function SelectionCheckbox({ isSelected, onChange }) {
  return (
    <>
      <input type="checkbox" style={{ display: "none" }} />
      <label className="checkbox checkbox-single">
        <input type="checkbox" checked={isSelected} onClick={onChange} />
        <span />
      </label>
    </>
  );
}

const groupingItemOnSelect = async ({
  ids,
  setIds,
  customerId,
  onSelection
}) => {
  if (ids.some(id => id === customerId)) {
    await setIds(ids.filter(id => id !== customerId));

    if (onSelection) {
      onSelection([...ids]);
    }
  } else {
    const newIds = [...ids, customerId];

    await setIds(newIds);
    if (onSelection) {
      onSelection([...newIds]);
    }
  }
};

function groupingAllOnSelect(props) {
  const { isSelected, setIds, entities } = props;

  if (!isSelected) {
    const allIds = [];
    entities.forEach(el => allIds.push(el.id));
    setIds(allIds);
  } else {
    setIds([]);
  }

  return isSelected;
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export function getSelectRow({ entities, ids, setIds, onSelection, ...props }) {
  return {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectAll: false,
    selectionHeaderRenderer: () => {
      const isSelected =
        entities && entities.length > 0 && entities.length === ids.length;

      return (
        <SelectionCheckbox
          isSelected={isSelected}
          onChange={() =>
            groupingAllOnSelect({
              isSelected,
              entities,
              setIds
            })
          }
        />
      );
    },
    selectionRenderer: ({ rowIndex }) => {
      const isSelected = ids.some(el => el === entities[rowIndex].id);

      return (
        <SelectionCheckbox
          isSelected={isSelected}
          onChange={() =>
            groupingItemOnSelect({
              ids,
              setIds,
              customerId: entities[rowIndex].id,
              onSelection
            })
          }
        />
      );
    },
    ...props
  };
}
